<template>
  <span class="edit-btn" @click="$emit('input', !value)" @mouseover="isHover = true" @mouseout="isHover = false">
    {{ label }} <CaratRight :class="{ down: value, hover: isHover }"
  /></span>
</template>

<script>
import CaratRight from '@/assets/icons/carat_right.svg';

export default {
  name: 'carat-button',
  components: { CaratRight },
  props: {
    value: Boolean, // isOpen
    label: String,
  },
  data() {
    return {
      isHover: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.edit-btn {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  svg {
    height: 10px;
    margin-left: 5px;
    transition: transform 300ms ease-out;
    &.down {
      margin-left: 8px;
      transform: rotate(90deg);
    }
    &.hover:not(.down) {
      transform: translateX(15px);
    }
    .stroke {
      stroke: $grey--dark;
    }
  }
}
</style>
