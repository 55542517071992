<template>
  <div class="directory-page content">
    <section class="general">
      <h3>General Department Contacts</h3>
      <div class="contact" v-for="contact in generalDepartmentContacts" :key="contact.id">
        <h4>
          {{ contact.name }}
          <EditButton v-model="contact.isOpen" label="Edit" />
        </h4>
        <div v-if="!contact.isOpen" class="container" key="static">
          <h6>{{ contact.email }}</h6>
        </div>
        <form v-else @submit.prevent="submitAdminDirectoryGeneral([contact])" key="edit">
          <input v-model="contact.newName" class="form-control" placeholder="Name" />
          <input v-model="contact.newEmail" type="email" class="form-control" placeholder="Email Address" />
          <div class="submit-container">
            <span></span>
            <button class="btn btn-primary" type="submit">Save</button>
          </div>
        </form>
      </div>
    </section>
    <section class="regional">
      <h3>Regional Contacts</h3>
      <div class="contact" v-for="(contacts, regionName) in regionalContacts" :key="regionName">
        <h4>
          {{ regionName }}
          <EditButton :value="isRegionOpen(regionName)" @input="toggleRegion(regionName)" label="Edit" />
        </h4>
        <div v-if="!isRegionOpen(regionName)" class="contact-container">
          <div v-for="contact in contacts" :key="contact.id" class="container">
            <span class="description">{{ contact.description }}</span>
            <h6>{{ contact.email }}</h6>
          </div>
        </div>
        <form v-else @submit.prevent="submitAdminDirectoryRegion(contacts)" key="edit">
          <div v-for="contact in contacts" :key="contact.id" class="contact-group">
            <input v-model="contact.newDescription" class="form-control" placeholder="Description" />
            <input v-model="contact.newEmail" type="email" class="form-control" placeholder="Email Address" />
          </div>
          <div class="submit-container">
            <span @click="addNewContact(contacts, regionName)" class="text-btn">+ Add Additional Contact</span>
            <button class="btn btn-primary" type="submit">Save</button>
          </div>
        </form>
      </div>
    </section>
    <div class="toast-container">
      <BaseToast v-if="toastMessage.isShown" :toast="toastMessage" />
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/api.service';
import { mapGetters } from 'vuex';
import BaseToast from '@/components/BaseToast.vue';
import EditButton from '@/components/CaratButton.vue';

export default {
  name: 'cms-directory',
  components: { BaseToast, EditButton },
  data() {
    return {
      generalDepartmentContacts: [],
      regionalContacts: {},
      regionsOpen: [],
      toastMessage: {
        status: 'danger',
        message: '',
        isShown: false,
      },
    };
  },
  created() {
    this.getDirectory();
  },
  computed: {
    ...mapGetters(['enums']),
  },
  methods: {
    getDirectory() {
      return ApiService.get('admin/directory/').then(({ data }) => {
        this.generalDepartmentContacts = data?.data['general_department_contacts'].map((contact) => ({
          ...contact,
          isOpen: false,
          newEmail: contact.email,
          newName: contact.name,
        }));
        this.enums.regions.forEach((region) => {
          this.regionalContacts[region.name] = data?.data['regional_contacts']
            .filter((contact) => contact.regionId === region.id)
            .map((contact) => ({
              ...contact,
              isOpen: false,
              newEmail: contact.email,
              newName: contact.name,
              newDescription: contact.description,
            }));
        });
      });
    },
    submitAdminDirectoryGeneral(contacts) {
      const putPayload = contacts.map((contact) => ({
        id: contact.id,
        email: contact.newEmail,
        name: contact.newName,
        description: contact.description,
        directoryCategoryId: contact.directoryCategoryId,
        regionId: contact.regionId,
      }));
      this.putDirectory(putPayload);
    },
    submitAdminDirectoryRegion(contacts) {
      const putPayload = contacts.map((contact) => ({
        id: contact.id,
        email: contact.newEmail,
        description: contact.newDescription,
        directoryCategoryId: contact.directoryCategoryId,
        regionId: contact.regionId,
        name: contact.name,
      }));
      this.putDirectory(putPayload);
    },
    putDirectory(putPayload) {
      ApiService.put('/admin/directory', putPayload).finally(() => {
        this.regionsOpen = [];
        this.getDirectory().then(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          this.toastMessage.status = 'success';
          this.toastMessage.message = 'You have successfully updated the directory.';
          this.toastMessage.isShown = true;
        });
      });
    },
    isRegionOpen(regionName) {
      return this.regionsOpen.some((region) => region === regionName);
    },
    toggleRegion(regionName) {
      const index = this.regionsOpen.findIndex((region) => region === regionName);
      if (index !== -1) {
        this.regionsOpen.splice(index, 1);
      } else {
        this.regionsOpen.push(regionName);
      }
    },
    addNewContact(contacts, regionName) {
      contacts.push({
        newEmail: null,
        newDescription: null,
        directoryCategoryId: 2,
        regionId: this.enums.regions.find((region) => region.name === regionName).id,
        name: regionName,
      });
      this.regionalContacts = { ...this.regionalContacts };
    },
  },
};
</script>

<style lang="scss" scoped>
.directory-page {
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: bold;
  }
  .general {
    margin-bottom: 40px;
    border-bottom: 1px solid $dividers;
  }
  .contact {
    margin-bottom: 40px;
    h4 {
      display: flex;
      margin-bottom: 15px;
      .edit-btn {
        margin-left: 20px;
      }
    }
    h6 {
      margin: 0;
      font-family: $font-family-base;
      font-size: 12px;
      font-weight: bold;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      max-width: 400px;
      input {
        margin-bottom: 20px;
      }
      .contact-group {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .submit-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        .text-btn {
          justify-self: flex-start;
          font-family: $wide;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      .description {
        margin-bottom: 15px;
        font-size: 12px;
      }
    }
  }
}
</style>
